<template>
    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3" v-if="data.permission.canEdit">
                    <div class="border-bottom pb-3">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <h2>Do this task to start your project</h2>
                            </div>
                            <div>
                                <input :disabled="projectStatus == 'Completed'" type="checkbox" hidden="hidden" id="configureSwitch" :checked="data.projectConfigCheck">
                                <label class="switch" for="configureSwitch" @click="completeConfig"> </label>
                                <label class="cursor-pointer ms-2" for="configureSwitch">Complete Configuration</label>
                            </div>
                        </div>
                    </div>
                    <div class="my-3">
                        <div class="row border-bottom mb-3">
                            <div class="col-2">
                                <div class="d-flex justify-content-center mb-3">
                                    <span class="me-2">
                                        <input type="checkbox" aria-checked="true" :checked="data.identification.checked"
                                               disabled="disabled" role="checkbox" value="" class="checkbox-ctl">
                                    </span>
                                    <span class="numbered-list"> 1 </span>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" :style="`width: ${data.identification.checked ? '100%' : '50%'}`" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div class="col-10">
                                <div class="company-data">
                                    <p class="mb-1"><span class="fw-bold"> Define project goals and identifications </span> </p>
                                    <p class="mb-3">Define project scope and parameters the others can understand the scope </p>
                                    <div class="d-flex mb-3">
                                        <div class="me-2">
                                            <a href="#" class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-Three" aria-expanded="true" aria-controls="collapse-Three">
                                                <font-awesome-icon :icon="['fas', 'angle-double-down']"></font-awesome-icon>
                                            </a>
                                        </div>
                                        <div>
                                            <router-link :to="{name: 'identification'}" class="btn btn-primary">Project Scope And Parameters</router-link>
                                        </div>
                                    </div>
                                    <div class="accordion accordion-flush" id="accordion-tree">
                                        <div class="accordion-item">
                                            <div id="collapse-Three" class="setup-submenu accordion-collapse collapse collapsed" aria-labelledby="heading-Three" data-bs-parent="#accordion-tree">
                                                <div class="treeview">
                                                    <ul>
                                                        <li v-for="(item, index) in data.dataList.items" :key="index">
                                                            <span class="me-2">
                                                                <input class="checkbox-ctl" type="checkbox" aria-checked="true"
                                                                    :checked="item.isChecked" disabled="disabled" role="checkbox" value="">
                                                            </span>
                                                            <span> {{ item.name }} </span>

                                                            <ul v-if="item.children" @loadstart="getChildItems(item, data.dataList.selection)">
                                                                <li v-for="(child, key) in item.children" :key="key">
                                                                    <span class="me-2">
                                                                        <input class="checkbox-ctl" type="checkbox" aria-checked="true"
                                                                               :checked="child.isChecked" disabled="disabled" role="checkbox" value="">
                                                                    </span>
                                                                    <span> {{ child.name }} </span>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row border-bottom mb-3">
                            <div class="col-2">
                                <div class="d-flex justify-content-center mb-3">
                                    <span class="me-2"><input type="checkbox" aria-checked="true" :checked="data.timeline.checked"
                                                              disabled="disabled" role="checkbox" value="" class="checkbox-ctl"></span>
                                    <span class="numbered-list"> 2</span>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" :style="`width: ${data.timeline.checked ? '100%' : '50%'}`" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div class="col-10">
                                <div class="company-data">
                                    <p class="mb-1"><span class="fw-bold"> Define High-Level Timeline </span> </p>
                                    <p class="mb-3">Define the high-level timeline of the project </p>
                                    <div class="d-flex mb-3">
                                        <div class="me-2">
                                            <a href="#" class="btn btn-primary" type="button">
                                                <font-awesome-icon :icon="['fas', 'angle-double-down']"></font-awesome-icon>
                                            </a>
                                        </div>
                                        <div>
                                            <router-link :to="{name: 'project-milestone'}" class="btn btn-primary">Project Timeline</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row border-bottom mb-3">
                            <div class="col-2">
                                <div class="d-flex justify-content-center mb-3">
                                    <span class="me-2">
                                        <input type="checkbox" aria-checked="true" :checked="data.projectUsers.checked"
                                               disabled="disabled" role="checkbox" value="" class="checkbox-ctl">
                                    </span>
                                    <span class="numbered-list"> 3</span>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" :style="`width: ${data.projectUsers.checked ? '100%' : '50%'}`" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div class="col-10">
                                <div class="company-data">
                                    <p class="mb-1"><span class="fw-bold"> Invite Project Leadership </span> </p>
                                    <p class="mb-3">Invite mandatory project leads to join the project  </p>
                                    <div class="d-flex mb-3">
                                        <div class="me-2">
                                            <a href="#" class="btn btn-primary" type="button">
                                                <font-awesome-icon :icon="['fas', 'angle-double-down']"></font-awesome-icon>
                                            </a>
                                        </div>
                                        <div>
                                            <router-link :to="{name: 'project-user'}" class="btn btn-primary">Project Users</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row border-bottom mb-3">
                            <div class="col-2">
                                <div class="d-flex justify-content-center mb-3">
                                    <span class="me-2">
                                        <input type="checkbox" aria-checked="true" :checked="data.matrix.checked"
                                               disabled="disabled" role="checkbox" value="" class="checkbox-ctl">
                                    </span>
                                    <span class="numbered-list"> 4</span>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" :style="`width: ${data.matrix.checked ? '100%' : '50%'}`" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div class="col-10">
                                <div class="company-data">
                                    <p class="mb-1"><span class="fw-bold"> Define Deliverable Matrix </span> </p>
                                    <p class="mb-3">Define the matrix of documents and tie them with milestones </p>
                                    <div class="d-flex mb-3">
                                        <div class="me-2">
                                            <a href="#" class="btn btn-primary" type="button">
                                                <font-awesome-icon :icon="['fas', 'angle-double-down']"></font-awesome-icon>
                                            </a>
                                        </div>
                                        <div>
                                            <router-link :to="{name: 'deliverable-matrix'}" class="btn btn-primary">Deliverable Matrix</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { useStore } from "vuex";
    import HelperFunction from "@/common/helpers";
    import {onMounted, computed} from "vue";
    import {useRoute} from "vue-router";
// import func from 'vue-editor-bridge';

    export default {
        name: "StartActivity",
        setup() {
            const { data, getAllData, editFormData, storeFormData, formPermissionResolver } = HelperFunction();
            const store = useStore();
            const route = useRoute()
            const project_id = route.params.id
            const projectStatus = computed(() => store.getters.getProjectStatus);
            let setParams = {
                check: true,
                project_id: project_id,
                type: 'activity',
            };
            data.identification = { checked: 0 }
            data.timeline       = { checked: 0 }
            data.projectUsers   = { checked: 0}
            data.matrix         = { checked: 0 }
            data.projectConfigCheck  = 0

            data.setURL = vueConfig.Project.Setup.StartActivityEndPoint
            onMounted(async () => {
                await getAllData(data.setURL, setParams)
                    .then((response) => {
                        let res = response.data.data
                        
                        data.dataList.items.map(x => {
                            if(x.children && x.children.length != 0){
                                let totalCount = 0

                                x.children.map(y => {
                                    totalCount += y.isChecked ? 1 : 0
                                })

                                x.isChecked = totalCount == x.children.length ? true : false
                            }
                        })

                        data.identification.checked = res.selection && res.selection.length == res.total ? 1 : 0;
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                await editFormData({url: `${vueConfig.Project.Setup.StartActivityStatusEndPoint}/${project_id}`})
                .then((response) => {
                    let res = response.data.data
                    data.timeline.checked = res.timeline.total > 0 ? 1 : 0
                    data.projectUsers.checked = res.project_users.total == res.project_users.accept_count ? 1 : 0
                    data.matrix.checked = res.matrix.total > 0 ? 1 : 0
                    data.projectConfigCheck = res.project.project_configured == 1 ? 1 : 0
                })
                .catch((error) => {
                    console.error(error)
                });
                
            });      

            // Uncheck parent if any child has uncheck for child lenght 5
            function uncheckParentL5() {
                let parentCheckMark = true;
                for (const key in data.dataList.items) {
                    if (data.dataList.items[key].hasOwnProperty('children')) {
                        let childObject = data.dataList.items[key].children;

                        for (const innerKey in childObject) {
                            if (!data.dataList.selection.includes(childObject[innerKey].id)) {
                                parentCheckMark = false;
                                break;
                            }
                        }
                        if (childObject.length == 5) break;
                    }
                    if (parentCheckMark === false) break;
                }
                
                return parentCheckMark;
            }
            
            // Uncheck parent if any child has uncheck for child length 1
            function uncheckParentL1() {
                let parentCheckMark = true;
                for (const key in data.dataList.items) {
                    if (data.dataList.items[key].hasOwnProperty('children')) {
                        let childObject = data.dataList.items[key].children;

                        if (childObject.length == 5) continue;

                        for (const innerKey in childObject) {
                            if (!data.dataList.selection.includes(childObject[innerKey].id)) {
                                parentCheckMark = false;
                                break;
                            }
                        }
                    }
                    if (parentCheckMark === false) break;
                }
                
                return parentCheckMark;
            }

            /** watch **/
            formPermissionResolver('start-activity', true, ['update']);
            /** watch end **/

            async function completeConfig() {
                if(this.projectStatus == "Completed") return

                let status = data.projectConfigCheck == 1 ? 0 : 1
                let url = `${vueConfig.Project.Setup.StartActivityStatusEndPoint}/${project_id}`
                await storeFormData({url: `${url}/${status}`})
                .then((res) => {
                    data.projectConfigCheck = status
                })
            }
            function getChildItems(item, child) {
                console.error(item, child)
            }

            return {
                data, completeConfig, getChildItems, uncheckParentL5, uncheckParentL1, projectStatus
            }
        }
    }
</script>

<style scoped>

</style>